::placeholder {
  font-style: italic;
  color: var(--grey-blue) !important;
  font-weight: 400;
}
.field > select {
  display: block;
  font-size: 0.9em;

  /* font-style: italic; */
  color: var(--grey-blue);
  width: 100%;
  height: 40px;

  line-height: 1.25rem;
  padding: 0 10px;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  margin: 5px 0 0px 0;
  border: none;
  border-radius: 1px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #292a2d;
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 0.65em auto, 100%;
}

.field.field__checkbox {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 20px;
  cursor: pointer;
  margin-bottom: 10px;
  letter-spacing: 0.05em;
  font-size: 0.8em;
}

.field.field__checkbox > input {
  position: absolute;
  margin-left: -20px;
  margin-top: 2px;
}

@media screen and (max-width: 767px) {
  .field.field__checkbox > input {
    width: initial;
  }
}

.form-error .glyphicon {
  margin-right: 5px;
}

form label {
  width: 100%;
}

form label > input {
  width: 100%;
}

form span.field-error {
  width: 98%;
  margin-left: 1%;
  color: white;
  background: #dd4444;
  border-top: 3px solid #b31c1c;
  border-radius: 0px 0 4px 4px;
  padding: 3px 6px 6px 6px;

  display: inline-block;
  font-weight: 300;
  font-size: 13px;
  text-transform: none;
}

form .checkbox > label {
  display: flex;
  flex-direction: row;
  align-items: center;

  color: var(--grey-blue);
  text-transform: uppercase;
  font-weight: 500;
  font-size: 0.7em;
  margin-left: 2px;
  letter-spacing: 0.05em;
}
form .checkbox > label > * {
  flex: 3;
}

form .checkbox > label > input {
  margin-top: 0;
}

form label.field__checkbox > input {
  width: initial;
}
.checkbox span {
  flex: 1;
}
.checkbox img {
  border-radius: 5px;
  margin: 20px 10px 0 0px;
  max-width: 100px;
  flex: 1;
}

@media screen and (max-width: 767px) {
  form .checkbox > label > input {
    width: initial;
  }
}
