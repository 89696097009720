@keyframes slideInFromRight {
  0% {
    transform: translateX(50%);
  }
  100% {
    transform: translateX(0);
  }
}

.global-notifications {
  position: fixed;
  right: 10px;
  bottom: 0;
}

.global-notifications .alert {
  color: white;
  background-color: black;
  border-radius: 0;
  border: none;

  position: relative;

  padding: 5px 50px 5px 20px;
  margin-bottom: 10px;
  animation: 0.2s ease-out 0s 1 slideInFromRight;
  border-radius: 5px;
}

.global-notifications .alert p {
  font-size: 0.9em;
  font-weight: 400;
  color: var(--white);
  padding: 0.5rem 1rem;
}

.global-notifications .alert .close {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  font-size: 16px;
  text-shadow: none;
  background: none;
  color: white;
  border: none;
}
.global-notifications .alert.alert-success {
  background: var(--success);
}
.global-notifications .alert.alert-danger {
  background: var(--alert-error);
}
.global-notifications .alert.alert-warning {
  background: var(--alert-warning);
}
.global-notifications .alert.alert-info {
  background: var(--main-color);
}
