.loading-overlay {
  position: fixed;
  background-color: black;
  opacity: 0.6;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 999;
}

@-moz-keyframes spin {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loading-spinner {
  border-radius: 50%;
  width: 24px;
  height: 24px;
  border: 0.25rem solid rgba(0, 0, 0, 0.5);
  border-top-color: white;
  -webkit-animation: spin 1s infinite linear;
  animation: spin 1s infinite linear;
  background-color: #33a9e6;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12px;
  margin-left: -12px;
}
