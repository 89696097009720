.header {
  background-color: #292a2d;
  border-bottom: 1px solid #282828;
  color: var(--header-color);
  width: 70px;
  height: 100%;
  position: absolute;
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header img.role {
  background: linear-gradient(180deg, #219cf5 0%, #0f4564 100%);
  height: 50px;
  padding: 2px;
  border-radius: 50%;
  margin-bottom: 20px;
}

.header .icons {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header .icons .icon {
  position: relative;
  display: flex;
  margin-bottom: 20px;
  color: #b0b0b0;
}

.header .icons .icon:hover {
  color: var(--accent);
}

.header .icons .icon.active {
  color: var(--main-color);
}

.header .icons .icon .count {
  display: inline-block;
  background-color: #ff0017;
  color: white;
  border-radius: 50%;
  padding: 2px;
  position: absolute;
  top: -4px;
  right: -5px;
  width: 14px;
  height: 14px;
  font-size: 8px;
  line-height: 10px;
  text-align: center;
}

@media screen and (min-width: 0) and (max-width: 767px) {
  .header {
    margin-bottom: 0;
    height: 70px;
    width: 100%;
    flex-direction: row;
  }

  .header > .role {
    margin-left: auto;
  }

  .header > .role > img.role {
    margin-bottom: 0;
  }

  .header .link-route {
    display: none;
  }

  .header > .icons {
    flex-direction: row;
    margin-left: 10px;
  }

  .header > .icons > .icon {
    margin: 0 10px;
  }

  .header .icons .icon {
    margin-right: 20px;
  }
}

.header .logout {
  display: inline-block;
  height: 40px;
  width: 40px;
  line-height: 30px;
  padding: 5px 10px;
  margin-top: 20px;
  border-radius: 5px;
  background: url('../../../Images/user.png') no-repeat;
  background-size: cover;
  -webkit-transition: width 1s, height 1s; /* Safari */
  transition: width 1s, height 1s;
}

.header .logout:hover {
  width: 50px;
  height: 50px;
}

/* from index.css */

@media only screen and (max-width: 991px) {
  .header {
    margin-bottom: 150px;
  }
}

.header.header--setup .header-logout-holder {
  margin-left: auto;
  padding-right: 20px;
}

.header.header--loggedout .header-logout-holder {
  margin-left: auto;
}
