.facebook-pages {
}

.facebook-pages .facebook__page {
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 8px 8px 8px 8px;
  border-radius: 3px;
  display: flex;
  align-items: center;
}

.facebook-pages .facebook__page:hover {
  background-color: #444;
}

.facebook-pages .facebook__page > img {
  width: 50px;
  margin-right: 10px;
  box-shadow: 0 0 5px black;
}
